import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Layout, BlogHeader, FeaturedPosts, PostGrid, CategoryGrid, FooterCta, Section } from 'components';
import { Cta } from 'slices';
import './articles.scss';

const Blog = (props) => {
  const blogData = useStaticQuery(graphql`
    query BlogPost {
      prismicBlog {
        data {
          blog_title {
            html
            text
          }
          blog_description {
            html
            text
          }
          blog_meta_description {
            html
            text
          }
          blog_meta_title {
            html
            text
          }
        }
      }
      featuredPosts: allPrismicBlogPost(
        filter: { data: { is_featured: { eq: true }, staging_only: { ne: true } } }
        sort: { fields: data___timestamp, order: DESC }
        limit: 3
      ) {
        nodes {
          uid
          dataRaw
          data {
            author {
              text
            }
            category {
              uid
              document {
                ... on PrismicCategory {
                  id
                  data {
                    category_name {
                      text
                    }
                  }
                }
              }
            }
            excerpt {
              text
              html
            }
            hero_image {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1600, aspectRatio: 1.77)
                }
              }
            }
            is_featured
            staging_only
            timestamp(formatString: "D MMMM, YYYY")
            title {
              html
              text
            }
          }
        }
      }
      recentPosts: allPrismicBlogPost(
        limit: 3
        sort: { order: DESC, fields: data___timestamp }
        filter: { data: { staging_only: { ne: true } } }
      ) {
        nodes {
          uid
          dataRaw
          data {
            author {
              text
            }
            category {
              uid
              document {
                ... on PrismicCategory {
                  id
                  data {
                    category_name {
                      text
                    }
                  }
                }
              }
            }
            excerpt {
              text
              html
            }
            hero_image {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 1200, aspectRatio: 1.77)
                }
              }
            }
            is_featured
            staging_only
            timestamp(formatString: "D MMMM, YYYY")
            title {
              html
              text
            }
          }
        }
      }
      financePosts: allPrismicBlogPost(
        limit: 3
        sort: { order: DESC, fields: data___timestamp }
        filter: { data: { category: { uid: { eq: "finance" } }, staging_only: { ne: true } } }
      ) {
        nodes {
          uid
          dataRaw
          data {
            author {
              text
            }
            category {
              uid
              document {
                ... on PrismicCategory {
                  id
                  data {
                    category_name {
                      text
                    }
                  }
                }
              }
            }
            excerpt {
              text
              html
            }
            hero_image {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 800, aspectRatio: 1.77)
                }
              }
            }
            is_featured
            staging_only
            timestamp(formatString: "D MMMM, YYYY")
            title {
              html
              text
            }
          }
        }
      }
      lifestylePosts: allPrismicBlogPost(
        limit: 3
        sort: { order: DESC, fields: data___timestamp }
        filter: { data: { category: { uid: { eq: "lifestyle" } }, staging_only: { ne: true } } }
      ) {
        nodes {
          uid
          dataRaw
          data {
            author {
              text
            }
            category {
              uid
              document {
                ... on PrismicCategory {
                  id
                  data {
                    category_name {
                      text
                    }
                  }
                }
              }
            }
            excerpt {
              text
              html
            }
            hero_image {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 800, aspectRatio: 1.77)
                }
              }
            }
            is_featured
            staging_only
            timestamp(formatString: "D MMMM, YYYY")
            title {
              html
              text
            }
          }
        }
      }
      propertyPosts: allPrismicBlogPost(
        limit: 3
        sort: { order: DESC, fields: data___timestamp }
        filter: { data: { category: { uid: { eq: "property" } }, staging_only: { ne: true } } }
      ) {
        nodes {
          uid
          dataRaw
          data {
            author {
              text
            }
            category {
              uid
              document {
                ... on PrismicCategory {
                  id
                  data {
                    category_name {
                      text
                    }
                  }
                }
              }
            }
            excerpt {
              text
              html
            }
            hero_image {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 800, aspectRatio: 1.77)
                }
              }
            }
            is_featured
            staging_only
            timestamp(formatString: "D MMMM, YYYY")
            title {
              html
              text
            }
          }
        }
      }
      designPosts: allPrismicBlogPost(
        limit: 3
        sort: { order: DESC, fields: data___timestamp }
        filter: { data: { category: { uid: { eq: "design" } }, staging_only: { ne: true } } }
      ) {
        nodes {
          uid
          dataRaw
          data {
            author {
              text
            }
            category {
              uid
              document {
                ... on PrismicCategory {
                  id
                  data {
                    category_name {
                      text
                    }
                  }
                }
              }
            }
            excerpt {
              text
              html
            }
            hero_image {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 800, aspectRatio: 1.77)
                }
              }
            }
            is_featured
            staging_only
            timestamp(formatString: "D MMMM, YYYY")
            title {
              html
              text
            }
          }
        }
      }
    }
  `);

  const { location } = props;
  const { recentPosts, featuredPosts, prismicBlog, propertyPosts, financePosts, lifestylePosts, designPosts } =
    blogData;
  const { nodes: newPosts } = recentPosts;
  const { nodes: featured } = featuredPosts;
  const { nodes: finance } = financePosts;
  const { nodes: property } = propertyPosts;
  const { nodes: lifestyle } = lifestylePosts;
  const { nodes: design } = designPosts;
  const { data: blogLandingData } = prismicBlog;

  const allTopCategories = [
    { title: 'Property', posts: property, titleLink: '/articles/property/' },
    { title: 'Finance', posts: finance, titleLink: '/articles/finance/' },
  ];

  const allBottomCategories = [
    { title: 'Lifestyle', posts: lifestyle, titleLink: '/articles/lifestyle/' },
    // { title: 'Design', posts: design, titleLink: '/articles/design/' },
    { title: 'Latest Posts', posts: newPosts },
  ];

  const topCategories = allTopCategories.filter((category) => category?.posts?.length > 0);
  const bottomCategories = allBottomCategories.filter((category) => category?.posts?.length > 0);

  const ctaData = {
    data: {
      primary: {
        variant: 'Light Blue',
        icon: 'Rocket',
        title: {
          html: '<span>Get qualified in <span class="highlight">3 minutes.</span><br>Be your own broker.</span>',
        },
        cta: 'Get Started',
      },
    },
  };

  return (
    <Layout location={location}>
      <section className="blog">
        <BlogHeader data={blogLandingData} />
        {featured.length > 0 && <FeaturedPosts posts={featured} />}
        <Section noWrapper>
          {topCategories.map((category) => (
            <PostGrid
              posts={category.posts}
              title={category.title}
              key={category.title}
              titleLink={category.titleLink}
            />
          ))}
        </Section>
        <CategoryGrid />
        <Section noWrapper>
          {bottomCategories.map((category) => (
            <PostGrid
              posts={category.posts}
              title={category.title}
              key={category.title}
              titleLink={category.titleLink}
            />
          ))}
        </Section>
        <Cta {...ctaData} />
        <FooterCta />
      </section>
    </Layout>
  );
};
export default Blog;
